$disclaimer-padding-bottom: 4vh;
$padding-top: 4vh;
$padding-bottom: calc(#{$margin-xl} + #{$disclaimer-padding-bottom});
$padding-horizontal: 4vw;
$column-width-s: 9rem;
$column-width: 13rem;
$column-width-l: 15rem;

footer {
  margin-top: auto;
}

.footer-base {
  position: relative;
  display: flex;
  background-color: $color-tinted-black;
  justify-content: center;
  padding: $padding-top $padding-horizontal $padding-bottom;

  > .nel-logo {
    display: none;
    height: 40px;
    margin-right: auto;
  }

  > nav {
    display: flex;
    flex-wrap: wrap;

    > .column {
      width: $column-width;
      font: $font-lato-s;
      padding: 0 2vw;
      box-sizing: content-box;

      &.contact {
        width: $column-width-l;
      }

      > h4 {
        color: $color-white;
        font-size: $size-p;
        font-weight: 700;
        text-transform: uppercase;
        margin: .8rem 0 .4rem;
      }

      a {
        display: block;
        line-height: 120%;
        color: $color-grey-DA;
        font-size: $size-input;
        margin: .6rem 0;
      }

      > address {
        margin: 0;

        > a {
          &.address {
            line-height: 175%;
          }

          &:first-child {
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }

  > small {
    position: absolute;
    bottom: $disclaimer-padding-bottom;
    font: $font-lato-s;
    color: $color-grey-mid;

    &.disclaimer {
      left: $padding-horizontal;
    }

    &.copyright {
      right: $padding-horizontal;
    }
  }
}

@media screen and (max-width: 770px) {
  .footer-base > nav {
    max-width: calc(#{$column-width-l} + #{$column-width-s} + 2 * #{$padding-horizontal});

    > .column {
      width: $column-width-l;

      &:nth-of-type(2) {
        width: $column-width-s;
      }

      > address > a {
        &:first-child {
          margin-bottom: .6rem;
        }

        &.address {
          margin-top: -.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .footer-base > small {
    &.disclaimer {
      right: $padding-horizontal;
      bottom: 2 * $disclaimer-padding-bottom;
      left: unset;
      padding-right: 2.3rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-base {
    > nav {
      flex-direction: column;
      align-items: center;

      > .column {
        text-align: center;
      }
    }

    > small {
      &.copyright,
      &.disclaimer {
        right: unset;
        width: 100%;
        text-align: center;
        padding-right: 0;

        &.disclaimer {
          bottom: 7vh;
        }
      }
    }
  }
}

@media screen and (min-width: 1050px) {
  .footer-base {
    justify-content: center;
  }
}

@media screen and (min-width: 1200px) {
  .footer-base > .nel-logo {
    display: block;
  }
}
