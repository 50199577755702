// Important is needed to override in-line styles added by GlideJS
// sass-lint:disable no-important
// sass-lint:disable class-name-format

$advice-control-size: 9rem;

.testimony-carousel {
  color: $color-tinted-black;
  text-align: center;
  padding-bottom: $margin-ml;

  > h2 {
    width: 95%;
  }

  > .glide {
    max-width: 800px;
    margin: $margin-ml auto;

    ul {
      display: flex;
      flex-direction: row;
    }

    ul > li {
      height: inherit;

      > .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: center;
      }
    }

    > .controls {
      > button {
        border-radius: 100%;
        padding: 0;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 2px 1px $brand-transparent;
        }
      }

      &.arrows {
        position: absolute;
        top: 25%;
        left: 50%;
        display: flex;
        width: calc(100% + 2 * #{$testimony-carousel-btn-size});
        transform: translateX(-50%);
        justify-content: space-between;

        > button {
          width: $testimony-carousel-btn-size;
          height: $testimony-carousel-btn-size;
          border: 2px solid $color-brand;
          background: none;
          background-image: url('../img/icons/chevron.svg');
          background-position: center;
          background-size: 30%;
          background-repeat: no-repeat;

          &.right {
            transform: rotate(180deg);
          }
        }
      }

      &.dots {
        margin-top: 1rem;

        > button {
          width: 1rem;
          height: 1rem;
          border: 0;
          background: $color-grey-DA;

          &.glide__bullet--active { /* stylelint-disable-line selector-class-pattern */
            cursor: default;
            background: $color-brand;
          }
        }
      }
    }

    p {
      width: 90%;
      max-width: 60rem;
      line-height: 130%;
      font-weight: light;
      margin: 0 auto;
    }

    h4 {
      width: 100%;
      margin-bottom: 0;

      > span {
        display: block;
        font: $font-lato-m;
        font-weight: light;
      }
    }

    // Taller slides for longer testimonies
    &.for-physicians {
      max-width: 1000px;

      ul {
        overflow: visible;

        > li {
          position: relative;

          > h4 {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      > .controls > button {
        &.dots {
          position: relative;
          top: 1rem;
        }
      }
    }
  }
}

.advisor-carousel {
  width: 1000px;
  padding: 0;
  margin-bottom: $margin-l;

  > h2 {
    width: 100%;
  }

  > .glide {
    margin: $margin-ml auto;

    > .controls {
      position: absolute;
      top: 50%;
      left: calc(-#{$advice-control-size} - 1rem);
      display: flex;
      width: 9rem;
      transform: translateY(-50%);
      flex-direction: column;
      justify-content: center;

      > button {
        width: $advice-control-size;
        height: 7rem;
        border: 0;
        background: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        transition: height 250ms ease-in-out, width 250ms ease-in-out;
        margin: .7rem 0;

        &:not(.glide__bullet--active) { /* stylelint-disable-line selector-class-pattern */
          width: 6rem;
          height: 3rem;
          filter: grayscale(100%);

          &:hover {
            filter: none;
            cursor: pointer;
          }
        }
      }
    }

    ul {
      // Synchronize timing between logo highlighting and slide changing
      transition-delay: 300ms !important;
      align-items: center;

      > li {
        display: flex;
        align-items: center;

        > .img-container {
          position: relative;
          width: 200px;
          height: 300px;
          overflow: hidden;
          margin: 0 35px;
          flex-shrink: 0;

          > img {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: 100%;
            transform: translate(-50%, -50%);
            flex-shrink: 1;
            clip-path: inset(0, 300px);
          }
        }

        > .headers-narrow {
          display: none;
        }

        > .advisor-info {
          > h3 {
            padding: 0;
          }

          > p {
            line-height: 135%;
            font-size: $size-input;
            margin: 0;
          }
        }
      }
    }
  }
}

.publications-carousel {
  display: flex;
  width: 2000px;
  max-width: none;
  overflow: hidden;
  margin: 0 auto;
  padding-left: $margin-xl;
  box-sizing: content-box;

  > h2 {
    display: inline-block;
    width: 300px;
    margin-top: $margin-xl;
    flex-grow: 0;
  }

  > .glide {
    width: 1700px !important;

    > .controls {
      position: absolute;
      top: 55%;
      left: -13rem;
      display: flex;
      width: 7rem;
      justify-content: space-between;

      > button {
        display: block;
        width: 3rem;
        height: 3rem;
        border: 1px solid $color-grey-DA;
        cursor: pointer;
        background: none;
        background-image: url('../img/icons/arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        border-radius: 100%;

        &.left {
          transform: rotate(180deg);
        }
      }
    }

    ul {
      overflow: visible;
    }

    .publication-card {
      height: 25rem;
      margin: $margin-ml 0;

      > img {
        width: 100%;
        margin: 1.8rem 0;
      }

      > .card-text {
        > p {
          &.authors {
            font: $font-montserrat-s;
            color: $color-tinted-black;
            font-size: $size-h4;
            margin-bottom: 1rem;
          }


          &.published > span {
            display: block;
            font-weight: 500;
            padding: 0;
          }
        }

        > img {
          position: absolute;
          right: 1.9rem;
          bottom: 1.9rem;
        }
      }
    }
  }
}

@media screen and (max-width: 2300px) {
  .publications-carousel {
    width: 1850px;

    > .glide {
      width: 1550px !important;
    }
  }
}

@media screen and (max-width: 1999px) {
  .publications-carousel {
    width: 1450px;

    > .glide {
      width: 1150px !important;
    }
  }
}

@media screen and (max-width: 1600px) {
  .publications-carousel {
    width: 1100px;

    > .glide {
      width: 850px !important;
    }
  }
}

@media screen and (max-width: 1400px) {
  .advisor-carousel {
    width: 800px;

    > .glide ul > li > .advisor-info {
      > p {
        line-height: 135%;
        font-size: $size-input;
      }

      > img {
        max-width: 25%;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .testimony-carousel > .glide {
    &.for-physicians {
      max-width: 800px;
    }
  }

  .publications-carousel {
    width: 1000px;
    margin-right: 0;
    padding-left: 0;

    > .glide {
      width: 760px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .advisor-carousel {
    width: 90%;
    max-width: none;
    padding-left: 9rem;
    box-sizing: border-box;

    h2 {
      padding-right: 6rem;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1150px) {
  .publications-carousel {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 4rem;

    > h2 {
      width: 100%;
      margin: 0 75px;
    }

    > .glide {
      width: 100% !important;

      > .controls {
        top: 100%;
        left: calc(75px + #{$width-card} / 2);
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .testimony-carousel > .glide {
    width: 90%;

    &.for-physicians {
      max-width: 600px;
    }

    &.for-patients {
      max-width: 600px;
    }
  }

  .publications-carousel {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: $margin-xl;

    > h2 {
      width: 90%;
      margin: 0 auto;
    }
  }

  .advisor-carousel {
    padding-left: 0;

    > h2 {
      padding: 0;
    }

    > .glide {
      padding-top: 9rem;

      ul > li {
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        > .img-container {
          width: 30%;
          height: 200px;
        }

        > .advisor-info {
          width: 100%;
          margin-top: $margin-ml;

          > p {
            display: none;
          }

          > h3,
          > h4 {
            text-align: center;
          }
        }
      }

      > .controls {
        top: 0;
        left: 50%;
        width: 75%;
        height: $advice-control-size;
        transform: translate(-50%, 0);
        align-items: center;
        flex-direction: row;

        > button {
          min-width: 10%;
          height: 50%;
          margin: 0 1rem;
          box-sizing: border-box;

          &.glide__bullet--active { /* stylelint-disable-line selector-class-pattern */
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .testimony-carousel {
    padding: 0;

    > .glide  {
      &.for-patients,
      &.for-physicians {
        max-width: 500px;
      }

      > .controls {
        &.arrows {
          display: none;
        }
      }
    }
  }

  .advisor-carousel {
    width: 95%;

    > .glide {
      padding-top: 5rem;

      > .controls {
        width: 100%;
        height: 5rem;
      }

      ul > li {
        > .img-container {
          width: 50%;
          height: 250px;
        }

        > .headers-narrow {
          display: none;
        }

        > .advisor-info {
          margin-top: $margin-ml;

          > p {
            display: none;
          }

          > h3,
          > h4 {
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
}
