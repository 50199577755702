.careers-content {
  > h2 {
    max-width: 100%;
    text-align: center;
    margin: 1rem auto;
  }

  > .button {
    margin-left: 0;
  }

  > .outer-container {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-end;

    > div {
      &:first-of-type {
        width: 100%;
        max-width: 650px;
      }

      &:last-of-type {
        display: flex;
        width: 100%;
        min-width: 257px;
        flex-grow: 1;
        flex-direction: column;
        margin: 1rem;

        > p {
          color: $color-brand;
          text-align: center;
          font-size: $size-label;
          margin: 0;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}

.careers-list {
  display: flex;

  > .card-container {
    margin: $margin-ml;
  }
}

@media screen and (min-width: 600px) {
  .careers-content {
    > .outer-container > div {
      &:last-of-type {
        flex-direction: row;
        margin: 1rem 0;

        > p {
          text-align: left;

          &:first-of-type {
            margin-right: 1rem;
          }

          &:last-of-type {
            margin-top: 0;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 781px) {
  .careers-content {
    margin-top: -5vw;

    > h2 {
      text-align: left;
      margin-left: 0;
    }

    > .outer-container > div {
      &:first-of-type {
        width: 50%;
        min-width: 350px;
      }

      &:last-of-type {
        width: 40%;
        max-width: 400px;
        flex-direction: column;
        margin-left: $margin-xl;

        > p {
          &:first-of-type {
            margin-left: 1rem;
          }

          &:last-of-type {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .careers-content {
    > .outer-container {
      flex-wrap: nowrap;
      align-items: flex-start;

      > div {
        &:last-of-type {
          width: 23rem;
          margin-left: 6rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .careers-content > .outer-container > div {
    &:last-of-type {
      margin-left: 8rem;
    }
  }
}

@media screen and (min-width: 2000px) {
  .careers-content {
    margin-top: -3vw;

    > h2 {
      max-width: 100%;
    }

    > .outer-container > div {
      &:first-of-type {
        width: 42rem;
      }
    }
  }
}
