.announce {
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: $color-white;
  justify-content: space-between;
  box-shadow: 0 1.75px 8.72px $shadow-round;

  > img {
    height: 70px;
  }

  > p {
    color: $color-brand;
    text-align: left;
    font-size: $size-hero-box-s;
    margin: 20px 30px;
  }

  > .button {
    flex: 0 0 10em;
  }

  & + .nav-top {
    position: sticky;
    top: 0;
    margin-bottom: -$height-nav; // Negates space taken by sticky navbar
  }
}

.nav-top {
  position: fixed;
  z-index: 2;
  display: flex;
  width: 100%;
  height: $height-nav;
  background-color: $color-white;
  justify-content: flex-end;
  align-items: center;
  opacity: .9;
  backdrop-filter: saturate(180%) blur(20px);

  > .navbar-toggler {
    display: none;
    width: 24px;
    height: 24px;
    border: 0;
    flex-shrink: 0;
    padding: 0;
    background: none;
    background-image: url('../img/icons/menu-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 2.2rem;

    &:hover {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }

  > .logo {
    width: 227px;
    height: 27px;
    margin-left: 2.2rem;
    margin-right: auto;
    padding: 8px 0;
    background-image: url('../img/base/nel-logo-dark.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  > .nav-list {
    display: flex;
    font: $font-lato-s;
    margin: 0;
    padding-left: 0;
    padding-right: $margin-ml;

    > .nav-item {
      display: inline-block;
      height: 100%;
      text-align: center;
      padding: 0;

      > a {
        display: inline-block;
        height: 100%;
        line-height: $height-nav;
        color: $color-tinted-black;
        padding: 0 10px;
        text-decoration: none;

        &:hover {
          color: $color-brand;
        }

        &.active {
          color: $color-brand;
          font-weight: 500;
        }
      }
    }

    > hr {
      height: 23px;
      border-left: 1px solid $color-white;
      margin: 0 10px;
    }
  }
}

.notification {
  position: fixed;
  top: $height-nav;
  display: flex;
  width: 100%;
  height: 0;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: hidden;
  justify-content: space-around;

  &.show-animation {
    animation-name: show;
    animation-duration: 3s;
  }

  @keyframes show {
    0% { visibility: visible; height: 0; }
    20% { height: $height-nav; }
    60% { height: $height-nav; }
    100% { visibility: hidden; height: 0; }
  }

  &.success {
    background-color: $color-success;
  }

  &.warning {
    background-color: $color-dark-red;
  }

  > p {
    position: absolute;
    bottom: 0;
    color: $color-white;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .announce {
    > img {
      display: none;
    }

    > p {
      font-size: $size-h4;
    }
  }
}

@media screen and (max-width: 1000px) {
  .nav-top {
    > .navbar-toggler {
      display: block;
    }

    > .nav-list {
      position: absolute;
      top: $height-nav;
      display: none;
      width: 100%;
      background-color: $color-white;
      flex-direction: column;
      align-items: center;
      padding: 0;

      > .nav-item > a {
        width: 230px;
      }

      &.expanded {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .announce {
    flex-direction: column;
    padding-bottom: 15px;

    > p {
      text-align: center;
      margin-bottom: 5px;
    }

    > .button {
      flex: unset;
    }
  }
}
