.contact-us {
  display: flex;
  max-width: 1400px;
  justify-content: center;
  flex-wrap: wrap;

  > h2 {
    width: 90%;
    margin: $margin-s auto $margin-xl;
  }

  > form {
    width: 50%;

    label {
      display: inline-block;
      color: $color-grey-64;
      font-size: $size-small;
      margin: 21px 0 9px;
    }

    > .row {
      display: flex;
      justify-content: space-between;

      > .name-group {
        display: inline-block;
        width: 49%;
        box-sizing: border-box;

        > label {
          margin-top: 0;
        }
      }
    }

    input {
      width: 100%;
      height: 3.5rem;
      font: $font-lato-s;
      line-height: 3.5rem;
      background-color: $color-grey-F2;
      border: 0;
      box-sizing: border-box;
      font-size: $size-small;
      padding: 0 10px;
      border-radius: 3px;

      &:focus {
        outline: none;
      }
    }

    > textarea {
      width: 100%;
      height: 32rem;
      font: $font-lato-s;
      line-height: 130%;
      background-color: $color-grey-F2;
      border: 0;
      font-size: $size-small;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 3px;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    > button {
      display: block;
      width: 189px;
      margin-left: auto;
      margin-right: 0;
    }
  }

  > .contact-info {
    max-width: 435px;
    font: $font-lato-m;
    color: $color-grey-64;
    margin-left: 7rem;

    .vat,
    .address-container,
    > address {
      font-size: $size-p;
      margin: $margin-m 0 $margin-ml;

      &:first-of-type {
        margin-top: $margin-m;
      }

      > h4 {
        line-height: 130%;
        color: $color-grey-64;
        font-size: $size-small;
        padding-bottom: 0;
        margin-top: 0;
      }

      > p {
        margin: 0;
      }

      > a {
        display: inline-block;
        color: $color-grey-64;
      }

      &.visit-us {
        display: flex;
        margin-top: $margin-xl;

        > .vat,
        > .address-container {
          margin-top: 0;
        }
      }

      > .vat {
        display: inline-block;
        margin-left: 7rem;
        margin-bottom: auto;
      }
    }

    > .map {
      display: block;
      width: 435px;
      height: 283px;
      background-image: url('../img/illustrations/map.jpg');
      background-repeat: no-repeat;
      background-position: bottom right;
    }
  }
}

@media screen and (max-width: 1400px) {
  .contact-us > .contact-info {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 1100px) {
  .contact-us {
    > form {
      width: 80%;
    }

    > .contact-info {
      width: 80%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .contact-us {
    > form > textarea {
      height: 25rem;
    }

    > .contact-info > .map {
      width: 100%;
    }
  }
}

@media screen and (max-width: 420px) {
  .contact-us > .contact-info > address > .vat {
    margin-left: 8vw;
  }
}
