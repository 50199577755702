.publication-card,
.card-container {
  position: relative;
  display: block;
  min-width: $width-card;
  max-width: $width-card;
  line-height: 117%;
  background: $color-white;
  box-shadow: $shadow-card;
  margin: 1rem auto;

  > .card-banner {
    width: 100%;
  }

  > .card-text {
    padding: 0 1.9rem 2.9rem;

    > p {
      @include clamp(10);

      font: $font-lato-s;
      color: $color-grey-64;
      margin: 0 0 1.5rem;

      &.published,
      &.card-author {
        position: absolute;
        bottom: 1.9rem;
        color: $color-tinted-black;
        font-weight: 600;
        margin-bottom: 0;

        > span {
          padding: 0 .5rem;
        }
      }
    }
  }

  &:link {
    &:hover {
      top: 1px;
      left: 1px;
      box-shadow: $shadow-card-hover;
    }
  }
}
