.round-item-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;

  > li {
    display: flex;
    text-align: center;
    border-radius: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 1.9rem;
    margin: 0 1rem;

    > .brain {
      display: none;
    }
  }
}

.company-data {
  position: absolute;
  top: 28vw;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  > li {
    width: 11.8rem;
    height: 11.8rem;
    background: $color-brand;

    > p {
      font: $font-montserrat-s;
      color: $color-white;
      font-size: $size-p;

      > strong {
        font-size: $size-h3;
      }
    }
  }
}

.values {
  margin-bottom: 4rem;

  > h2 {
    margin-bottom: 3rem;
  }

  > ul > li {
    width: 11rem;
    height: 11rem;
    font: $font-lato-m;
    margin: .5rem;
  }
}

.columns {
  display: flex;
  justify-content: space-between;

  > article {
    width: 45%;

    > h2 {
      text-align: left;
      font-size: $size-h2-s;
      margin-left: 0;
    }
  }
}

.company-people {
  display: flex;
  width: 100%;
  max-width: 1300px;
  justify-content: center;
  flex-wrap: wrap;

  > h2 {
    width: 90%;
    margin: 0 auto $margin-ml;
  }

  > .person {
    display: inline-block;
    width: 13rem;
    text-align: center;
    margin: 1.5rem 2rem;

    > h3 {
      font: $font-montserrat-m;
      padding: 1rem 0 .3rem;

      > span {
        display: block;
        font-size: $size-p;
      }
    }

    > h4 {
      color: $color-brand;
      font-weight: 500;
      padding-top: 0;
    }

    > p {
      margin: 0;
    }

    > img {
      width: 80%;
      clip-path: circle();
      margin: 0 auto;
    }
  }
}

.company-contact {
  text-align: center;
  padding: $margin-s;
  margin-bottom: $margin-m;

  > .container {
    > .header-container {
      margin-bottom: $margin-m;
    }

    > .row {
      margin-bottom: $margin-s;

      > .map {
        min-height: 300px;
        background-image: url('../img/map.png');
        background-repeat: no-repeat;
        background-position: bottom right;
      }

      > .contact-details {
        > p {
          text-align: left;
          margin: 0 0 0 $margin-m;
          font-size: $size-small;

          > a {
            color: $color-black;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .round-item-container ul > li {
    margin: 0 .5rem;
  }

  .company-data > li {
    width: 9rem;
    height: 9rem;

    > p {
      font-size: $size-small;

      > strong {
        font-size: $size-label;
      }
    }
  }

  .values {
    > ul > li {
      width: 8.5rem;
      height: 8.5rem;
      font-size: $size-input;
      padding: 1.7rem;
    }
  }

  .board {
    max-width: 40rem;
  }
}

@media (max-width: 1150px) {
  .columns > article {
    width: 47%;
  }

  .values {
    > ul > li {
      width: 7rem;
      height: 7rem;
    }
  }
}

@media (max-width: 900px) {
  .company-data {
    top: 43vw;
    max-width: 48rem;
    flex-wrap: wrap-reverse;

    > li {
      position: relative;
      width: 20rem;
      height: unset;
      background: none;
      justify-content: flex-start;
      padding: 0;
      margin: .5rem 1rem;

      > p {
        display: inline-block;
        font: $font-montserrat-s;
        color: $color-grey-64;
        text-align: left;
        font-weight: 600;
        padding: 0;
        margin: 10px 0 0 4rem;

        > strong {
          font-weight: 600;
          font-size: $size-hero-box-s;
        }
      }

      > .brain {
        top: 0;
        display: block;
      }
    }
  }

  .hero ~ main {
    &.company {
      margin-top: 10rem;
    }
  }

  .columns {
    flex-direction: column;
    align-items: center;

    > article {
      width: 80%;
      margin-top: $margin-ml;
    }
  }

  .values {
    > ul {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 620px) {
  .company-data {
    position: initial;
    transform: unset;

    > li {
      width: 90%;
    }
  }

  .hero ~ main {
    &.company,
    > .columns {
      margin-top: 0;
    }
  }
}
