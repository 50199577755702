.careers,
.privacy-policy {
  > .news-post {
    padding-top: $height-nav;
  }
}

.news-post {
  margin-top: 0;

  > .container {
    margin: 0 auto $margin-s;

    > .banner {
      max-width: 1200px;
      height: 40vw;
      min-height: 200px;
      max-height: 600px;
      margin: $margin-xs 0 $margin-m;
      object-fit: cover;
      object-position: 0 10%;
    }

    > .row {
      display: flex;
      justify-content: center;

      > .article-card {
        max-width: 66.66667%;
        flex: 0 0 66.66667%;
        padding: 0 $margin-ml 0 1rem;

        > .contact-us {
          margin-top: 60px;
          justify-content: space-between;
          flex-wrap: unset;

          > h2 {
            max-width: 400px;
            font: 1.5rem Montserrat,sans-serif;
            text-align: left;
            font-weight: 400;
            margin-right: 35px;
          }
        }

        > .banner {
          width: 100%;
          margin-bottom: $margin-xs;
        }

        > .card-author {
          text-align: center;
        }

        > .title {
          color: $color-black;
          text-align: center;
          font-size: $size-h3-l;
          font-weight: 500;
          margin-bottom: $margin-xs;
        }

        > p,
        > ul {
          line-height: 130%;
          font-size: $size-p;

          > a {
            color: $color-black;
            text-decoration: underline;

            &:hover {
              color: $color-brand;
            }
          }

        }

        > ul, ol {
          padding-left: 40px;

          > li, p {
            font: $font-lato-s;
            color: $color-grey-64;
            font-size: $size-p;
          }

          &:not(ol) {
            list-style: initial;
          }
        }

        > h2 {
          color: $color-brand;
          font-size: $size-h3;
          margin: 3rem 0 $margin-ml;
        }

      }

      > .read-more {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;

        > h3 {
          text-align: center;
          font-size: $size-label;
          text-transform: none;
          padding: 0 0 1rem;
        }

        .card-container {
          box-shadow: 0 5px 40px -25px $color-grey-64;

          &:hover {
            top: 1px;
            left: 1px;
            background: $shadow-a;
            box-shadow: 0 3px 30px -20px $color-black;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .news-post > .container > .row > .article-card {
    width: 85%;
    max-width: none;
    flex: unset;
    padding: 0;

    > .contact-us {
      margin-top: 60px;
      flex-wrap: wrap;
      justify-content: center;

      > h2 {
        width: 100%;
        max-width: 100%;
        font: 1.5rem Montserrat,sans-serif;
        text-align: center;
        margin-bottom: 30px;
        margin-right: 0;
        font-weight: 400;
      }
    }
  }

  .read-more {
    display: none;
  }
}

@media (min-width: 768px) {
  .read-more {
    position: relative;
    overflow: auto;
    padding: 0;

    > .read-more-inner {
      position: absolute;
      top: 2.5rem;
      bottom: $margin-xs;
      width: 100%;
      overflow: auto;

      > .news-card {
        > .card-inner {
          position: relative;
          padding: 13.5rem $margin-s $margin-xs;
          margin: 3.5rem $margin-xs $margin-l;

          &:hover {
            > .card-banner {
              top: -3.5rem;
              height: 16rem;
            }
          }

          > .card-banner {
            top: -3rem;
            width: calc(100% - 3rem);
            height: 15rem;
          }
        }
      }
    }
  }
}
