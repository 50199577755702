$size-hero-h1: calc(#{$size-h1} + 2vw);

// clip-path needs to be unset before applying new one to avoid stacking
// sass-lint:disable no-duplicate-properties
.hero {
  position: relative;
  background-clip: border-box;
  flex-shrink: 0;

  > .languages {
    position: absolute;
    top: 5%;
    right: $margin-language-menu;
    z-index: 1;
    width: 4.1rem;
    color: $color-brand;
    background-color: $language-menu-background;
    margin-top: 55px;
    text-transform: uppercase;
    box-shadow: 0 0 5px 5px $language-menu-background;
    border-radius: 5px;
    padding: .2rem;
    box-sizing: content-box;

    > .current-language {
      display: flex;
      font: $font-montserrat-m;
      color: $color-brand;
      cursor: default;
      font-weight: 700;
      justify-content: space-evenly;
      align-items: center;
      margin: 0;

      &::after {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        transform: rotate(270deg);
        transition: transform .1s ease-out;
        content: '';
        background: url('/img/icons/chevron.svg') center / contain no-repeat;
        padding-left: 1rem;
        box-sizing: content-box;
      }
    }

    > ul {
      max-height: 0;
      font: $font-montserrat-m;
      list-style: none;
      margin: .1rem 0 0;
      padding: 0;
      transition: max-height .15s ease-in;
      overflow: hidden;

      > li {
        & > a,
        & > a:visited {
          display: block;
          width: 100%;
          color: $color-brand;
          padding: .3rem 0;

          &:hover {
            font-weight: 600;
          }
        }
      }
    }

    &:hover,
    &:focus-within {
      border-radius: 3px 3px 10px 10px;

      .current-language {
        &::after {
          transform: rotate(90deg);
        }
      }

      & > ul {
        max-height: 150px; // update when adding items to the menu
      }
    }
  }

  &.frontpage {
    > h1 {
      top: 6vw;
      font-size: $size-hero-h1;
    }

    ~ main {
      margin-top: -4vw;

      > .hero-box {
        top: 38vw;
      }
    }
  }

  &.narrow > h1 {
    top: 10vw;
    max-width: 41vw;
  }

  ~ main {
    margin-top: -7vw;
  }

  > h1 {
    position: absolute;
    top: 12vw;
    left: 50%;
    display: block;
    color: $color-white;
    margin-top: 55px;
    transform: translateX(-50%);

    &.two-lines {
      margin-top: 20px;
    }

    > span {
      color: $color-brand;

      &.top {
        display: block;
      }
    }
  }

  > .image-wrapper {
    height: 50vw;
    overflow: hidden;
    clip-path: ellipse(140% 60% at 50% 6vw);

    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 45vw;
      content: '';
      background-image: $hero-image-filter;
      opacity: .9;
    }

    > img {
      position: absolute;
      width: 100%;
    }
  }

  > .video-wrapper {
    height: 50vw;
    overflow: hidden;
    clip-path: ellipse(140% 60% at 50% 6vw);

    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 60vw;
      content: '';
      background: $hero-video-filter;
    }

    > video {
      position: absolute;
      width: 100%;
    }
  }
}

.hero-box {
  position: absolute;
  top: 32vw;
  left: 50%;
  display: flex;
  min-width: 600px;
  max-width: 46rem;
  max-height: 11rem;
  background-color: $color-white;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: $shadow-hero-box;
  padding: 2.5rem 3rem;
  margin: 0 auto;

  > p {
    display: block;
    font: $font-montserrat-l;
    line-height: 130%;
    color: $color-brand;
    text-align: center;
    font-size: $size-hero-box;
    font-weight: 500;
    margin: 0;
  }
}

@media screen and (max-width: 1600px) {
  .hero {
    ~ main {
      margin-top: -4vw;
    }

    &.frontpage ~ main {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1300px) {
  .hero {
    &.frontpage ~ main {
      margin-top: $margin-ml;
    }
  }
}

@media screen and (max-width: 1100px) {
  .hero {
    &.frontpage {
      > .video-wrapper {
        clip-path: unset;
        clip-path: ellipse(88% 60% at 50% 18vw);
      }

      ~ main {
        margin-top: calc(10rem - 5vw);
      }

      > .hero-box {
        top: 42vw;
      }
    }

    &.narrow > h1 {
      max-width: 46rem;
      font-size: $size-h2;
    }
  }

  .hero-box {
    width: 45%;
    min-width: 400px;
    padding: 2rem 3rem;

    > p {
      font-size: $size-hero-box-s;
    }
  }
}

@media screen and (max-width: 900px) {
  .hero ~ main {
    margin-top: 0;
  }

  .hero-box {
    width: 60%;
  }
}

@media screen and (max-width: 680px) {
  .hero {
    &.frontpage {
      > .video-wrapper {
        clip-path: unset;
        clip-path: ellipse(100% 60% at 50% 20vw);
      }

      > h1 {
        top: 17vw;
        font-size: $size-h1;
      }

      ~ main {
        margin-top: 0;

      }

      > .hero-box {
        top: -4rem;
      }
    }

    > h1 {
      top: 12vw;
      max-width: 50rem;
      transform: translate(-50%, -50%);
      font-size: $size-h2-s;
    }

    > h1.two-lines {
      top: 18vw;
    }

    ~ main {
      margin-top: -14vw;
    }
  }

  .hero-box {
    position: relative;
    top: -18vw;
    left: unset;
    width: 75%;
    min-width: 350px;
    max-width: 450px;
    transform: unset;
    padding: 1.5rem 4rem;
    margin: 0 auto;

    > p {
      font-size: $size-hero-box-s;
    }
  }
}

@media screen and (max-width: 500px) {
  .hero {
    top: $height-nav; // Move header below navbar on mobile to make the UI less cramped
    margin-bottom: 35px;

    > .languages {
      margin-top: 0;
    }

    &.frontpage {
      > h1 {
        top: 10%;
        font-size: $size-h2-s;
      }

      ~ main > .hero-box {
        top: 1rem;
        margin-bottom: $margin-xl;
      }
    }

    > h1,
    > .two-lines,
    &.narrow > h1 {
      top: 3%;
      font-size: $size-h3;

      &.two-lines {
        top: 12vw;
      }
    }

    ~ main {
      margin-top: 0;
    }
  }

  .hero-box {
    width: 85%;
    min-width: 300px;
    padding: 1.5rem 2rem;
  }
}

@media screen and (max-width: 420px) {
  .hero {
    ~ main {
      margin-top: 1rem;
    }

    &.frontpage {
      > h1 {
        top: 0;
      }
    }

    > .languages {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 2000px) {
  .hero {
    ~ main {
      margin-top: -10vw;
    }

    &.frontpage ~ main {
      margin-top: -4vw;
    }
  }
}

@media screen and (min-width: 2300px) {
  .hero {
    &.frontpage ~ main {
      margin-top: -4vw;
    }
  }
}
