.collaborations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: $margin-ml 0;

  > .collaboration-category {
    display: flex;
    width: 80%;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: $margin-ml 0;

    > h2 {
      width: 100%;
      text-align: center;
      margin-bottom: $margin-ml;
    }

    > img {
      height: 5rem;
      margin: $margin-ml $margin-xl;
    }
  }
}

@media screen and (max-width: 1100px) {
  .collaborations > .collaboration-category {
    width: 100%;

    > h2 {
      width: 90%;
    }

    > img {
      height: 4rem;
      margin: $margin-s $margin-ml;
    }
  }
}

@media screen and (max-width: 400px) {
  .collaborations > .collaboration-category {
    margin-bottom: 0;

    > img {
      height: 3rem;
      margin: $margin-s $margin-ml;
    }
  }
}
