.ce-disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: $color-disclaimer;
  padding: 20px;

  > p {
    font: $font-lato-xs;
    line-height: 115%;
    color: $color-white;
    text-align: center;
    font-size: $size-h4;
    margin: 1px 0;

    > br {
      display: none;
    }
  }
}

.scattergram {
  display: block;
  width: 95%;
  max-width: 55rem;
  margin: 0 auto $margin-xl;
}

.nelli-info {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 45%;
    max-width: 550px;
  }

  p {
    line-height: 150%;
    color: $color-grey-64;
    font-size: $size-input;
    margin: 0;

    &:last-of-type {
      margin-top: 1rem;
    }

    &.orange {
      width: 40%;
      max-width: 25rem;
      font: $font-montserrat-l;
      line-height: 130%;
      color: $color-brand;
      text-align: center;
      font-size: $size-label;
      margin: 0 5rem 0 0;
    }
  }
}

.data {
  text-align: center;

  > h2 {
    width: 100%;
    font: $font-montserrat-xl;
    color: $color-grey-64;
    font-weight: 500;
    margin: 1rem 0 3vw;
  }

  > h3 {
    width: 95%;
    font-weight: 400;
    margin: 2rem auto;

    &:last-of-type {
      margin-top: 3rem;
    }
  }

  > ul {
    display: flex;
    margin: 0;

    > li {
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin: 1rem 3vw;

      > .number {
        font: $font-montserrat-xxl;
        color: $color-brand;
      }

      > p {
        color: $color-tinted-black;
        font-size: $size-label;
        font-weight: light;
      }
    }
  }

  sup,
  > p {
    &:not(.registered) {
      color: $color-grey-64;
      font-weight: light;
      margin-top: 0;
    }
  }

  > p {
    font-size: $size-small;
  }
}

.learn-more {
  > h2 {
    padding-bottom: 1.5rem;
  }

  > .flex-display {
    display: flex;
    justify-content: center;

    > .learn-more-card {
      position: relative;
      width: $width-card;
      margin: 2rem 2vw;
      box-shadow: $shadow-card;
      background: $color-grey-FE;
      border-radius: 1rem;
      padding-bottom: calc(#{$height-button} + 2rem);

      > img {
        width: calc(100% - 2rem);
        padding: 1rem;
      }

      > h4 {
        position: absolute;
        bottom: 2rem;
        width: 100%;
        font: $font-montserrat-l;
        color: $color-grey-64;
        text-align: center;
        font-weight: 700;
      }

      &:hover {
        top: 1px;
        left: 1px;
        background: $shadow-a;
        box-shadow: $shadow-card-hover;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .nelli-info {
    flex-direction: column;

    > div {
      width: 80%;
      max-width: 550px;
    }

    p {
      &.orange {
        width: unset;
        margin: 0 0 $margin-s;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .learn-more > .flex-display {
    flex-direction: column;
    align-items: center;

    > .learn-more-card {
      height: 60px;
      background: $color-dark-yellow;
      padding: 0;
      margin: 1rem;

      > h4 {
        position: initial;
        font: $font-montserrat-m;
        line-height: 60px;
        color: $color-white;
        margin: 0;
        font-weight: 700;
      }

      > img {
        display: none;
      }

      &:hover {
        top: 0;
        left: 0;
        cursor: pointer;
        background: $color-dark-yellow;
        box-shadow: $shadow-button;
      }
    }
  }

  .data {
    > h2 {
      width: 95%;
      margin: 1rem auto;
    }

    > h3 {
      margin-top: $margin-l;
    }

    > ul {
      &:first-of-type {
        flex-wrap: wrap;

        > li > p {
          margin-bottom: 0;
        }
      }
    }
  }
}
