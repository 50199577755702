section {
  &.how-it-happens {
    margin-top: $margin-xxl;
    background-image: url('../img/illustrations/arrow_vertical.svg');
    background-repeat: no-repeat;
    background-size: 27%;
    background-position: 55% 40%;

    > h2 {
      margin-bottom: $margin-m;
    }

    p {
      line-height: 160%;
    }

    > .subtitle {
      width: 100%;
      font: $font-montserrat-m;
      line-height: 135%;
      color: $color-black;
      text-align: center;
      margin: 0 auto;
    }

    > figure {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: $margin-m 0;

      &.device {
        margin-bottom: 0;

        > img {
          min-height: 27rem;
          max-height: 27rem;
          padding-left: 100px;
        }

        > figcaption {
          max-width: 350px;
          padding: $margin-ml $margin-ml $margin-m 0;

          > p {
            padding: 0;
          }
        }
      }

      &.report {
        margin-top: -2vw;
      }

      > img {
        min-height: 21rem;
        max-height: 21rem;
      }

      > figcaption {
        display: block;
        max-width: 36rem;
        padding: $margin-ml 0 $margin-m $margin-ml;
      }
    }
  }

  &.learn-more-link {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: $margin-m 0;
    margin: $margin-ml 0 $margin-xl;

    > h3 {
      width: 100%;
      margin: $margin-s 0 $margin-m;
    }

    > button {
      display: inline-block;
      box-shadow: none;
    }
  }

  &.content-video {
    > video {
      width: 100%;
      margin-top: $margin-ml;
    }
  }

  &.patient-data {
    > .box-container {
      h3 {
        font-size: $size-h2;
      }
    }

    > .disclaimer {
      text-align: center;
      font-size: $size-small;
      font-weight: light;
      margin-top: .5rem;
    }
  }
}

@media screen and (max-width: 2000px) {
  section {
    &.how-it-happens {
      background-size: 25%;
      background-position: 58% 40%;
    }
  }
}

@media screen and (max-width: 1300px) {
  section {
    &.how-it-happens {
      background-size: 29%;
      background-position: 60% 40%;

      > figure {
        &.device > img {
          min-height: 25rem;
          max-height: 25rem;
        }

        &.report > img {
          min-height: 19rem;
          max-height: 19rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  section {
    &.how-it-happens {
      width: 95%;
      max-width: none;
      background-size: 27%;
      background-position: 59% 45%;

      > figure {
        &.device > img {
          min-height: 22rem;
          max-height: 22rem;
        }

        &.report > img {
          min-height: 18rem;
          max-height: 18rem;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  section {
    &.how-it-happens {
      background-image: none;

      > figure {
        margin: 0;

        &.device {
          flex-direction: column-reverse;
          margin: $margin-xl 0 $margin-ml;

          > figcaption {
            max-width: none;
            padding: 0;
          }

          > img {
            padding: 0;
          }
        }

        &.report {
          flex-direction: column;
        }

        > img {
          height: 22rem;
        }

        > figcaption {
          width: 70%;
          max-width: none;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  section {
    &.how-it-happens {
      br {
        display: none;
      }

      > figure {
        &.report {
          > img {
            max-width: 90%;
            height: auto;
            min-height: unset;
          }
        }

        > figcaption {
          width: 90%;
        }

        &.device {
          > img {
            width: 90%;
          }
        }
      }
    }
  }
}
