@import 'const';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'card';
@import 'careers';
@import 'carousels';
@import 'company';
@import 'contact';
@import 'hero';
@import 'footer';
@import 'index';
@import 'navbar';
@import 'news-post';
@import 'for-physicians';
@import 'for-patients';
@import 'science';

body,
html {
  min-height: 100vh;
  background-color: $color-white;
  font-family: $montserrat;
  margin: 0;
}

html {
  font-size: $base-size-s;
}

body {
  display: flex;
  flex-direction: column;
}

ul {
  padding: 0;
}

section {
  box-sizing: border-box;
}

main {
  &.news,
  &.contact,
  &.privacy-policy {
    padding-top: $margin-xxl;
  }

  > section {
    width: 90%;
    max-width: $width-main;
    margin: $margin-xl auto;

    &.wide {
      width: 100%;
      max-width: none;
    }
  }
}

h2 {
  width: 95%;
  font: $font-montserrat-xl;
  color: $color-brand;
  text-align: center;
  margin: 1rem auto;
  font-weight: 700;
}

h4 {
  font: $font-lato-m;
  color: $color-tinted-black;
  margin: 1rem 0;
  font-weight: 700;
}

p {
  font: $font-lato-m;
  color: $color-grey-64;
  font-size: $size-p;
}

h1 {
  width: 100%;
  color: $color-white;
  text-align: center;
  cursor: default;
  font-family: Montserrat, sans-serif;
  font-size: $size-h1;
  font-weight: 900;
  margin: 0;
}

h3 {
  width: 100%;
  line-height: 120%;
  color: $color-brand;
  font-size: $size-h3;
  font-weight: 700;
  margin: 0;
}

.titlecase {
  text-transform: capitalize;
}

address,
a {
  text-decoration: none;
  font-style: normal;
}

.button {
  display: flex;
  width: 268px;
  height: $height-button;
  font: $font-montserrat-m;
  color: $color-white;
  text-align: center;
  border: 0;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;
  background: $color-dark-yellow;
  border-radius: 12px;
  margin: 5px 10px;

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-button;
  }

  &:active {
    background-color: $color-dark-yellow-4d;
  }

  &:focus {
    outline: none;
  }
}

.highlighted {
  color: $color-brand;
}

.left {
  text-align: left;

  > h2,
  > h3,
  > h4 {
    text-align: left;
  }
}

sup,
small {
  font-size: $size-registered;

  &.registered {
    &.menu {
      vertical-align: top;
    }
  }

  &.symbol {
    font-weight: initial;
    font-size: $size-symbol;
  }
}

.grey-bg {
  width: 100%;
  max-width: none;
  background-color: $color-grey-F2;
}

.boxes {
  width: 100%;
  max-width: none;
  padding: 0;
}

.box-container {
  display: flex;
  padding: 0 1vw;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  > div {
    display: block;
    max-width: 350px;
    text-align: center;
    margin: $margin-ml $margin-m;

    &.info-item {
      display: flex;
      width: 14rem;
      font: $font-lato-s;
      line-height: 103%;
      color: $color-grey-64;
      text-align: center;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      font-size: $size-p;

      h4 {
        font: $font-montserrat-l;
        color: $color-brand;
        font-weight: 700;
        text-transform: uppercase;
        margin: 3% 0 4%;
        padding: 0;
      }

      > img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;

        &.asymmetric {
          width: 3.2rem;
          padding-left: .2rem;
        }
      }
    }

    > h3 {
      padding: 0 0 1rem;
      font-weight: 400;
    }

    > p {
      font: $font-lato-m;
      margin: 0;
    }
  }
}

.news > .gallery {
  margin-top: $margin-l;
}

.gallery {
  max-width: gallery-width(4);
  margin: $margin-xxl auto;

  > h2 {
    display: inline-block;
    width: 100%;
    color: $color-brand;
    text-align: left;
    padding: 0 0 $margin-m $margin-s;
  }

  > .container {
    > .header-container {
      text-align: center;
    }

    > .row {
      margin: $margin-ml 0;
    }
  }

  > .cards {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 0;

    > .card-container {
      width: 23rem;
      margin: $margin-s;
    }
  }
}

.badge-container > h3 {
  width: 100%;
  text-align: center;
  font-size: $size-h2-s;
  padding-bottom: 0;
}

.badges {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: $margin-m 0;

  > .badge-wrapper {
    display: flex;
    align-items: center;
    margin: $margin-s $margin-l;

    > img {
      height: 6rem;
    }

    > p {
      display: inline-block;
      max-width: 350px;
      color: $color-blue;
      font-size: $size-h4;
      margin: 0 0 0 $margin-s;
    }
  }
}

.impact-data {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;

  > .round {
    display: flex;
    width: 11rem;
    height: 11rem;
    font: $font-lato-m;
    color: $color-white;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 100%;
    background: $color-tinted-black;
    box-shadow: 0 1.75px 8.72px $shadow-round;
    margin: $margin-ml;

    &:first-of-type {
      background: $color-dark-yellow;
    }

    &:nth-of-type(3) {
      background: $color-dark-red;
    }

    > .number {
      display: block;
      font: $font-montserrat-l;
      font-weight: 700;
    }
  }
}

.hear-more {
  position: relative;
  background-color: $color-grey-DA;
  padding: $margin-xl 2vw;

  > .chevron {
    position: absolute;
    top: -1px;
    left: 50%;
    width: 5.3rem;
    height: 5.3rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background: $color-white;
    clip-path: polygon(80% 80%, 0 100%, 100% 0%);
  }

  > h2 {
    width: 100%;
    text-align: center;
    font-size: $size-h2;

    > a {
      display: inline-block;
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.instruction {
  display: flex;
  justify-content: center;

  > article {
    max-width: 900px;
    min-height: 300px;
    padding: 2 * $height-nav 2rem;

    > h2 {
      width: max-content;
      margin-bottom: 3rem;
    }

    > p {
      line-height: 150%;
      color: $color-black;
      margin-top: 2rem;
    }

    > ol {
      font: $font-lato-s;
      font-size: $size-p;

      > li {
        line-height: 130%;
        margin: .4rem 0;
      }
    }
  }
}

// 404 page
.error-page {
  > .not-found {
    display: flex;
    width: 100%;
    max-width: none;
    height: 40vh;
    min-height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 55px;
    box-sizing: content-box;

    > hgroup {
      width: 90%;

      > h3 {
        color: $color-tinted-black;
        text-align: center;
        margin-bottom: $margin-l;
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  .gallery {
    max-width: gallery-width(3);
  }
}

@media screen and (max-width: 1099px) {
  .gallery {
    > .cards > .card-container > .card-text > .summary {
      display: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  main > section {
    max-width: $width-main-xs;
  }

  .hear-more {
    padding: $margin-xl 0;
  }
}

@media screen and (max-width: 780px) {
  main > section {
    max-width: $width-main-xxs;
    margin: 3rem auto;
  }

  html {
    font-size: $base-size-xs;
  }

  .box-container {
    flex-direction: column;
    align-items: center;

    > div,
    > .info-item {
      margin: 1rem;
    }
  }
}

@media screen and (max-width: 684px) {
  .gallery {
    max-width: gallery-width(2, $margin-s);

    > h2 {
      padding-left: 0;
    }

    > .cards {
      justify-content: center;

      > .card-container {
        margin: $margin-xs;

        > .card-text > .summary {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 586px) {
  h2 {
    font-size: $size-h3-l;
  }

  .badges > .badge-wrapper {
    flex-direction: column;

    > p {
      text-align: center;
      margin: $margin-s 0 0;
    }
  }

  .hear-more {
    padding: $margin-l 0;
  }
}

@media screen and (min-width: 2000px) {
  html {
    font-size: $base-size-m;
  }
}

@media screen and (min-width: 2300px) {
  html {
    font-size: $base-size-l;
  }
}
