$list-line-height: 3.5rem;

.brain {
  position: absolute;
  display: block;
  width: 3rem;
  height: $list-line-height;
}

.challenges {
  max-width: 1050px;

  > h2 {
    font-size: $size-h3-l;
    margin: 0 0 2rem;
  }

  > ul {
    padding-left: 22px;

    > li {
      min-height: 2.2rem;
      font: $font-lato-m;
      line-height: 140%;
      color: $color-grey-64;
      font-size: $size-hero-box-s;
    }
  }
}

.value {
  > p {
    max-width: 1100px;
    padding-left: $margin-s;
    padding-right: $margin-s;
    margin: $margin-l auto;
  }

  > ul {
    display: flex;
    max-width: 1100px;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
    margin: $margin-l auto;

    > li {
      width: 46%;
      max-width: 700px;
      font: $font-montserrat-m;
      line-height: $list-line-height;
      color: $color-brand;
      font-weight: 500;
      margin: $margin-s;

      > h3 {
        display: inline-block;
        padding: $margin-xs 0 0 $margin-xl;
        box-sizing: border-box;
      }

      > p {
        padding-top: 0;
      }
    }
  }
}

.physician-description {
  padding: $margin-xl 0;

  > p {
    width: 90%;
    font: $font-montserrat-l;
    line-height: 135%;
    color: $color-brand;
    text-align: center;
    font-weight: 500;
    margin: 0 auto;
  }
}

.nelli-components {
  padding-bottom: 0;

  > .flow-arrow {
    display: block;
    width: 35%;
    margin: 0 auto;

    // Flip every other flow arrow
    &:nth-of-type(odd) {
      transform: scaleX(-1);
      padding-left: 5rem;
    }

    &:nth-of-type(even) {
      padding-right: 10rem;
    }
  }

  > figure {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }

    > img {
      display: block;
      min-height: 24rem;
      max-height: 24rem;
    }

    > figcaption {
      display: block;
      width: 50%;
      max-width: 27rem;
      padding-bottom: 1rem;
      margin: 2rem;

      > h3 {
        font: $font-montserrat-xl;
        font-weight: 700;
        padding-left: 0;
      }
    }
  }
}

.professional-testimonials {
  > h3 {
    width: 100%;
    text-align: center;
  }
}

// Keep workflow diagram wider than default section on small screens
@media screen and (max-width: 1300px) {
  .nelli-components {
    width: 90%;

    > figure {
      margin: 0;

      > img {
        min-height: 22rem;
        max-height: 22rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .nelli-components {
    width: 90%;

    > figure > img {
      min-height: 20rem;
      max-height: 20rem;
    }
  }
}

@media screen and (max-width: 880px) {
  .value > ul {
    flex-direction: column;
    align-items: center;

    > li {
      width: 90%;
      max-width: 600px;
      margin: 0;

      > h3 {
        width: calc(100% - 4rem);
      }

      > p {
        margin-top: 0;
      }
    }
  }

  .nelli-components {
    > figure {
      flex-direction: column;

      &:nth-of-type(even) {
        flex-direction: column;
      }

      > img {
        max-width: 100%;
        min-height: unset;
      }

      > figcaption {
        width: 80%;
        max-width: 35rem;
      }
    }

    > .flow-arrow {
      display: none;
    }
  }
}

@media screen and (max-width: 670px) {
  .physician-description > p {
    font-size: $size-h3;
  }
}

@media screen and (max-width: 570px) {
  .physician-description > p {
    font-size: $size-label;
  }

  .nelli-components > figure > figcaption > h3 {
    font-size: $size-h2-s;
  }
}

// rem is increased at 2000px, make element wider to accommodate larger text
@media screen and (min-width: 2000px) {
  .value > ul {
    max-width: 1200px;
  }
}
