// colors
$color-brand: #FF530D;
$color-dark-red: #D5450B;
$color-dark-yellow: #F4A950;
$color-dark-yellow-4d: #D7984D;
$color-blue: #034EA2;
$color-disclaimer: #6794C7;
$color-black: #000;
$color-tinted-black: #1D1B1B;
$color-white: #FFF;
$color-success: #7ED321;
$color-grey-mid: #8F8E8B;
/* stylelint-disable scss/dollar-variable-pattern */
$color-grey-FE: #FEFEFE;
$color-grey-F9: #F9F9F9;
$color-grey-F8: #F8F8F8;
$color-grey-F2: #F2F2F2;
$color-grey-EF: #EFEFEF;
$color-grey-DA: #DADADA;
$color-grey-C8: #C8C8C8;
$color-grey-C4: #C4C4C4;
$color-grey-96: #969696;
$color-grey-64: #646464;
/* stylelint-enable scss/dollar-variable-pattern */

// effect colors
$brand-transparent: rgba(255, 83, 13, .4);
$color-filter-top: rgba(100, 100, 100, .5);
$color-filter-bottom: rgba(239, 239, 239, .05);
$hero-image-filter: linear-gradient(180deg, $color-filter-top 2.69%, $color-filter-bottom 103.34%);
$hero-video-filter: rgba(29, 27, 27, .2);
$language-menu-background: rgba(255, 255, 255, .02);

// font sizes
$base-size-xs: 14px;
$base-size-s: 15px;
$base-size-m: 16px;
$base-size-l: 17px;
$size-small: 1rem;
$size-label: 1.5rem;
$size-input: 1.1rem;
$size-p: 1.2rem;
$size-h1: calc(2rem + 2vw);
$size-h2: 2.5rem;
$size-h2-s: 2.2rem;
$size-h3-l: 2rem;
$size-h3: 1.7rem;
$size-h4: .9rem;
$size-registered: 60%;
$size-symbol: 70%;
$size-hero-box: 1.8rem;
$size-hero-box-s: 1.3rem;

// margins
$margin-xs: .5rem;
$margin-s: 1rem;
$margin-m: $margin-s + $margin-xs;
$margin-ml: $margin-s * 2;
$margin-l: $margin-s * 3;
$margin-xl: $margin-s * 4;
$margin-xxl: $margin-s * 6;
$margin-language-menu: 2vw;

// fonts
$montserrat: 'Montserrat', sans-serif;
$lato: 'Lato', sans-serif;
$font-montserrat-xs: .9rem $montserrat;
$font-montserrat-s: 1.3rem $montserrat;
$font-montserrat-m: 1.5rem $montserrat;
$font-montserrat-l: 2rem $montserrat;
$font-montserrat-xl: 2.5rem $montserrat;
$font-montserrat-xxl: 3rem $montserrat;
$font-lato-xxs: .6rem $lato;
$font-lato-xs: .8rem $lato;
$font-lato-s: 1rem $lato;
$font-lato-m: 1.2rem $lato;
$font-lato-l: 1.7rem $lato;
$font-lato-xl: 2.2rem $lato;
$font-lato-xxl: 3rem $lato;

// shadows
$shadow-hero-text: 0 0 1px $color-black;
$shadow-card: 0 5px 45px -30px $color-black;
$shadow-card-hover: 0 3px 30px -20px $color-black;
$shadow-hero-box: 0 5px 40px -20px $color-black;
$shadow-button: 0 1px 6px rgba(0, 0, 0, .25);
$shadow-round: rgba(0, 0, 0, .25);
$shadow-a: rgba(240, 240, 240, .1);

// other
$link-overlay-size: calc(100% - 4rem);
$input-border: 1px solid $color-grey-C8;
$checkbox-size: 26px;
$width-main: 1200px;
$width-main-s: 950px;
$width-main-xs: 800px;
$width-main-xxs: 600px;
$width-card: 20rem;
$height-button: 51px;
$height-nav: 55px;
$testimony-carousel-btn-size: 3rem;

@function gallery-width($number-of-cards, $card-margin: $margin-s) {
  @return calc(#{$width-card} * #{$number-of-cards} + 2 * #{$number-of-cards} * #{$card-margin});
}

@mixin clamp($lines) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}
